import { BaseEntity } from '@prg/prg-core-lib';
import { GenericFailure } from 'src/app/element-risks/models/generic-failure-model';

export class ElementType extends BaseEntity {
  /**
   * TranslatedName
   */
  public label: string;
  /**
   * parent id
   */
  public parentId?: string;
  /**
   * children
   */
  public children?: ElementType[];

  /**
   * generic failures
   */
  public genericFailures?: GenericFailure[];

  constructor(init?: Partial<ElementType>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * helper enum for work element types
 */
export enum ElementTypeNames {
  Method = 'method',
  Machine = 'machine',
  Process = 'process',
  Product = 'product',
  Labor = 'labor',
}

import { PrgMenuItem } from '@prg/prg-core-lib';
import { ElementTypeNames } from '../elementTypes/models/element-type.model';

/**
 * Constant that defines the menu items for main menu
 */
export const SIDEBAR_MENU: PrgMenuItem[] = [
  new PrgMenuItem({
    id: 'home',
    icon: 'pi pi-home',
    routerLink: [''],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
  }),

  new PrgMenuItem({
    icon: 'pi pi-box',
    id: 'product',
    routerLink: ['/work-element/list'],
    queryParams: { ElementTypeName: `${ElementTypeNames.Product}` },
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
  }),

  new PrgMenuItem({
    icon: 'pi pi-ellipsis-h',
    id: 'action',
    routerLink: ['/entity-types/action'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
  }),
  new PrgMenuItem({
    icon: 'pi pi-link',
    id: 'action-priority',
    routerLink: ['/actions/action-priority'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
  }),
  new PrgMenuItem({
    icon: 'pi pi-shield',
    id: 'factor-value',
    routerLink: ['/factor-value'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
  }),

  // new PrgMenuItem({
  //   icon: 'pi pi-sitemap',
  //   id: 'process',
  //   routerLink: ['/work-element/list'],
  //   queryParams: { ElementTypeName: `${ElementTypeNames.Process}` },
  //   routerLinkActiveOptions: { exact: true },
  //   translationBasePath: 'components.sidebar.',
  // }),
  {
    id: 'workelement',
    icon: 'pi pi-microsoft',
    translationBasePath: 'components.sidebar.',
    items: [
      {
        icon: 'pi pi-server',
        id: 'machine',
        routerLink: ['/work-element/list'],
        queryParams: { ElementTypeName: `${ElementTypeNames.Machine}` },
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.workelement.',
      },
      {
        icon: 'pi pi-reply',
        id: 'method',
        routerLink: ['/work-element/list'],
        queryParams: { ElementTypeName: `${ElementTypeNames.Method}` },
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.workelement.',
      },
      {
        icon: 'pi pi-user',
        id: 'labor',
        routerLink: ['/work-element/list'],
        queryParams: { ElementTypeName: `${ElementTypeNames.Labor}` },
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.workelement.',
      },
    ],
  },

  // new PrgMenuItem({
  //   icon: 'pi pi-list',
  //   id: 'element-risks',
  //   routerLink: ['/element-risks/stepper'],
  //   routerLinkActiveOptions: { exact: true },
  //   translationBasePath: 'components.sidebar.',
  // }),
  new PrgMenuItem({
    icon: 'pi pi-sliders-h',
    id: 'management',
    translationBasePath: 'components.sidebar.',
    permission: '*',
    resource: '*',
    items: [
      new PrgMenuItem({
        id: 'state-models',
        icon: 'pi pi-clock',
        routerLink: ['/state-models'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'list',
        resource: 'state-models',
      }),
      new PrgMenuItem({
        icon: 'pi pi-users',
        id: 'list',
        routerLink: ['/entity-types/riskmanagementteam'],
        alternateActiveRouterLink: ['/risk-management-team'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.riskmanagementteam.',
      }),

      new PrgMenuItem({
        icon: 'pi pi-id-card',
        id: 'list',
        routerLink: ['/entity-types/client'],
        alternateActiveRouterLink: ['/entity-types/client'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.client.',
      }),

      new PrgMenuItem({
        icon: 'pi pi-th-large',
        id: 'list',
        routerLink: ['/entity-types/department'],
        alternateActiveRouterLink: ['/entity-types/department'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.department.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-exclamation-triangle',
        id: 'list',
        routerLink: ['/entity-types/genericfailure'],
        alternateActiveRouterLink: ['/entity-types/genericfailure'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.genericfailure.',
      }),

      new PrgMenuItem({
        icon: 'pi pi-share-alt',
        id: 'list',
        routerLink: ['/entity-types/specialcharacteristic'],
        alternateActiveRouterLink: ['/entity-types/specialcharacteristic'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.specialcharacteristic.',
      }),

      new PrgMenuItem({
        icon: 'pi pi-tags',
        id: 'list',
        routerLink: ['/element-type'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.element-type.',
      }),

      new PrgMenuItem({
        id: 'users',
        icon: 'pi pi-user-edit',
        routerLink: ['/users'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'list',
        resource: 'userprofile',
      }),
    ],
  }),

  new PrgMenuItem({
    icon: 'pi pi-exclamation-triangle',
    id: 'occurrences',
    routerLink: ['/entity-types/occurrence'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
  }),

  // new PrgMenuItem({
  //   icon: 'pi pi-cog',
  //   id: 'administration',
  //   translationBasePath: 'components.sidebar.',
  //   permission: '*',
  //   resource: '*',
  //   items: [
  //     {
  //       id: 'user-management',
  //       icon: 'pi pi-user-edit',
  //       translationBasePath: 'components.sidebar.',
  //       permission: '*',
  //       resource: '*',
  //       items: [
  //         {
  //           icon: 'pi pi-user-edit',
  //           id: 'roles-scopes',
  //           routerLinkString:
  //             // eslint-disable-next-line @typescript-eslint/quotes
  //             "['/' + this.userManagementConfig.userManagementBasePath + '/roles-scopes']",
  //           routerLinkActiveOptions: { exact: true },
  //           translationBasePath: 'components.sidebar.user-management.',
  //         },
  //         {
  //           icon: 'pi pi-user',
  //           id: 'user-management-scopes',
  //           routerLinkString:
  //             // eslint-disable-next-line @typescript-eslint/quotes
  //             "['/' + this.userManagementConfig.userManagementBasePath + '/user/7675e77d-03e6-454c-8124-27688580db30']",
  //           routerLinkActiveOptions: { exact: true },
  //           translationBasePath: 'components.sidebar.user-management.',
  //         },
  //       ],
  //     },

  //     new PrgMenuItem({
  //       id: 'entity-types',
  //       icon: 'pi pi-database',
  //       // eslint-disable-next-line @typescript-eslint/quotes
  //       routerLinkString: "['/' + this.entityTypesConfig.entityTypeBasePath]",
  //       routerLinkActiveOptions: { exact: true },
  //       translationBasePath: 'components.sidebar.',
  //       permission: 'list',
  //       resource: 'entitytype',
  //     }),

  //     // new PrgMenuItem({
  //     //   id: 'state-models',
  //     //   icon: 'pi pi-file',
  //     //   // eslint-disable-next-line @typescript-eslint/quotes
  //     //   routerLinkString: "['/' + this.stateModelsConfig.stateModelsBasePath]",
  //     //   routerLinkActiveOptions: { exact: true },
  //     //   translationBasePath: 'components.sidebar.',
  //     //   permission: 'list',
  //     //   resource: 'statemodel',
  //     // }),

  //     new PrgMenuItem({
  //       id: 'lookup-table',
  //       icon: 'pi pi-map',
  //       // eslint-disable-next-line @typescript-eslint/quotes
  //       routerLinkString: "['/' + this.lookupTableConfig.lookupTableBasePath]",
  //       routerLinkActiveOptions: { exact: true },
  //       translationBasePath: 'components.sidebar.',
  //       permission: 'list',
  //       resource: 'lookuptable',
  //     }),
  //     new PrgMenuItem({
  //       id: 'queries',
  //       icon: 'pi pi-filter',
  //       // eslint-disable-next-line @typescript-eslint/quotes
  //       routerLinkString: "['/' + this.queriesConfig.queriesRoutingPath]",
  //       routerLinkActiveOptions: { exact: true },
  //       translationBasePath: 'components.sidebar.',
  //       permission: 'list',
  //       resource: 'query',
  //     }),
  //     new PrgMenuItem({
  //       id: 'dashboard',
  //       icon: 'pi pi-chart-bar',
  //       translationBasePath: 'components.sidebar.',
  //       permission: 'list',
  //       resource: 'dashboard',

  //       items: [
  //         new PrgMenuItem({
  //           icon: 'pi pi-plus-circle',
  //           id: 'create',
  //           routerLinkString:
  //             // eslint-disable-next-line @typescript-eslint/quotes
  //             "['/'  + this.dashboardConfig.dashboardRoutingBasePath + '/' + this.dashboardConfig.dashboardConfigRoutingPath] +'/new'",
  //           routerLinkActiveOptions: { exact: true },
  //           translationBasePath: 'components.sidebar.dashboard.',
  //         }),
  //         new PrgMenuItem({
  //           icon: 'pi pi-list',
  //           id: 'list',
  //           routerLinkString:
  //             // eslint-disable-next-line @typescript-eslint/quotes
  //             "['/' + this.entityTypesConfig.entityTypeBasePath + '/dashboard']",
  //           routerLinkActiveOptions: { exact: true },
  //           translationBasePath: 'components.sidebar.dashboard.',
  //         }),
  //         new PrgMenuItem({
  //           icon: 'pi pi-eye',
  //           id: 'display',
  //           routerLinkString:
  //             // eslint-disable-next-line @typescript-eslint/quotes
  //             "['/' + this.dashboardConfig.dashboardRoutingBasePath + '/' + this.dashboardConfig.dashboardDisplayRoutingPath]",
  //           routerLinkActiveOptions: { exact: true },
  //           translationBasePath: 'components.sidebar.dashboard.',
  //         }),
  //       ],
  //     }),
  //   ],
  // }),
];

/**
 * Constant that defines the menu items for user menu
 */
export const USER_MENU_HEADER: PrgMenuItem[] = [
  {
    id: 'user-menu',
    items: [
      { separator: true },
      {
        icon: 'pi pi-user',
        id: 'my-account', //Key da tradução
        visibleString: '!!this.user',
        routerLink: '/users/my-account/profile',
      },
      {
        icon: 'pi pi-sign-out',
        commandString: 'this.onClickLogout',
        id: 'logout',
        visibleString: '!!this.user',
      },
      {
        icon: 'pi pi-sign-in',
        visibleString: '!this.user',
        id: 'login',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingLoginPath,]",
      },
    ],
  },
];
/**
 * Constant that defines the menu items for language menu on header
 */

export const LANGUAGE_MENU_HEADER: PrgMenuItem[] = [
  {
    id: 'language-menu',
    items: [
      { separator: true },
      {
        id: 'pt',
        commandString: '($event) => this.changeLanguage($event.item.id)',
      },
      {
        id: 'en',
        commandString: '($event) => this.changeLanguage($event.item.id)',
      },
    ],
  },
];

import { BaseEntity } from '@prg/prg-core-lib';
import { UserProfile } from 'src/app/users/models/user-profile.model';

export class RiskManagementTeam extends BaseEntity {
  public riskManagementTeamMembers: RiskManagementTeamMember[];

  constructor(init?: Partial<RiskManagementTeam>) {
    super();
    Object.assign(this, init);
  }
}

export class RiskManagementTeamMember extends BaseEntity {
  public riskManagementTeamId: string;
  public riskManagementTeam: RiskManagementTeam;
  public userProfileId: string;
  public userProfile: UserProfile;
  public riskManagementTeamMemberApplicationRoles: RiskManagementTeamMemberApplicationRoles[];

  constructor(init?: Partial<RiskManagementTeamMember>) {
    super();
    Object.assign(this, init);
  }
}

export class RiskManagementTeamMemberApplicationRoles extends BaseEntity {
  public riskManagementTeamMemberId: string;
  public applicationRoleId: string;

  constructor(init?: Partial<RiskManagementTeamMemberApplicationRoles>) {
    super();
    Object.assign(this, init);
  }
}

export class RiskManagementTeamMemberToken {
  /**
   * RiskManagementTeamMember roles array
   */
  role: string[] = null;
  /**
   * RiskManagementTeamMember scopes array
   */
  scopes: string[] = null;
  /**
   * Type of token (normally "Bearer")
   */
  typ: string = null;
  /**
   * User unique name
   */
  unique_name: string = null;
  /**
   * Expiration Time of Token (seconds since Unix epoch)
   * @type {number}
   */
  exp: number = null;
  /**
   * Issued at (seconds since Unix epoch)
   * @type {number}
   */
  iat: number = null;
  /**
   * Unique identifier of this token
   * @type {string}
   */
  jti: string = null;
  /**
   * Encoded token
   */
  encodedToken: string = null;
  /**
   * Constructor
   */
  constructor(init?: Partial<RiskManagementTeamMemberToken>) {
    Object.assign(this, init);
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@prg/prg-core-lib';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'risk-management-team',
    loadChildren: () =>
      import('./risk-management-team/risk-management-team.page.module').then(
        (m) => m.RiskManagementTeamPageModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'riskmanagementteam' }],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.page.module').then((m) => m.UsersPagePageModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'user' }],
  },
  {
    path: 'work-element',
    loadChildren: () =>
      import('./work-element/work-element.module').then(
        (m) => m.WorkElementModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'workelement' }],
  },
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'element-type',
    loadChildren: () =>
      import('./elementTypes/element-type.module').then(
        (m) => m.ElementTypePageModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'list', resource: 'elementtype' }],
  },
  {
    path: 'factor-value',
    loadChildren: () =>
      import('./factor-value/factor-value.module').then(
        (m) => m.FactorValuePageModule
      ),
  },
  {
    path: 'element-risks',
    loadChildren: () =>
      import('./element-risks/element-risk.module').then(
        (m) => m.ElementRiskModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'list', resource: 'elementrisk' }],
  },
  {
    path: 'actions',
    loadChildren: () =>
      import('./actions/actions.module').then((m) => m.ActionsPageModule),
  },
  {
    path: 'occurrences',
    loadChildren: () =>
      import('./occurrences/occurrences.module').then(
        (m) => m.occurrencesPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

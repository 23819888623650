import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { RiskManagementTeamMemberService } from '../services/risk-management-team-member.service';

/**
 * Injectable
 */
@Injectable()
/**
 * RiskManagementTeamMember Interceptor that handles and modified requests to api
 */
export class RiskManagementTeamMemberInterceptor implements HttpInterceptor {
  private readonly URLS_TO_INTERCEPT: string[] = ['workelement/update'];
  private readonly RISK_MANAGEMENT_TEAM_ID = 'riskManagementTeamId';
  /**
   * Constructor
   * @param {RiskManagementTeamMemberService} teamMemberService
   */
  constructor(private teamMemberService: RiskManagementTeamMemberService) {}

  /**
   * Interceptor Main method
   * @param {HttpRequest<unknown>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<unknown>>}
   */
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.body == null) {
      return next.handle(req);
    }

    let intercepts = false;
    const requestUrl = req.url.toLowerCase();
    this.URLS_TO_INTERCEPT.forEach((url) => {
      if (!intercepts && requestUrl.indexOf(url.toLowerCase()) !== -1) {
        intercepts = true;
      }
    });

    const riskManagementTeamId = req.body[this.RISK_MANAGEMENT_TEAM_ID];

    if (
      !intercepts ||
      riskManagementTeamId == null ||
      !riskManagementTeamId.length
    ) {
      return next.handle(req);
    }

    const token =
      this.teamMemberService.getTeamMemberToken(riskManagementTeamId);
    if (
      token == null ||
      token.encodedToken == null ||
      !token.encodedToken.length
    ) {
      return next.handle(req);
    }

    req = req.clone({
      headers: req.headers.set(
        'Team-Member-Authorization',
        'Bearer ' + ' ' + token.encodedToken
      ),
    });

    return next.handle(req);
  }
}
